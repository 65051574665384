<template>
    <div class="table-skeleton">
        <div class="row">
            <div class="col-12 col-sm-6 col-md-3 mb-2">
                <ui-skeleton class="table-skeleton__input w-100" type="input" />
            </div>
            <div class="col-12 col-sm-6 col-md-auto mb-2">
                <ui-skeleton class="table-skeleton__input" type="input" />
            </div>
            <div class="col-12 col-sm-6 col-md-auto d-none d-md-block mb-2">
                <ui-skeleton class="table-skeleton__input" type="input" />
            </div>
            <div class="col-12 col-sm-6 col-md-auto d-none d-md-block mb-2">
                <ui-skeleton class="table-skeleton__input" type="input" />
            </div>
            <div class="col-12 col-sm-6 col-md-auto d-none d-md-block mb-2">
                <ui-skeleton class="table-skeleton__input" type="input" />
            </div>
            <div class="col-12 col-sm-6 col-md-auto d-none d-md-block mb-2">
                <ui-skeleton class="table-skeleton__input" type="input" />
            </div>
        </div>
        <div class="d-flex justify-content-end mb-4">
            <ui-skeleton type="button" class="mr-2" />
            <ui-skeleton type="button" />
        </div>
        <ui-table :items="items" :fields="fields">
            <template #column(1)>
                <ui-skeleton class="table-skeleton__input" />
            </template>
            <template #column(2)>
                <ui-skeleton class="table-skeleton__input" />
            </template>
            <template #column(3)>
                <ui-skeleton class="table-skeleton__input" />
            </template>
            <template #column(4)>
                <ui-skeleton class="table-skeleton__input" />
            </template>
            <template #column(5)>
                <ui-skeleton class="table-skeleton__input" />
            </template>
            <template #column(6)>
                <ui-skeleton class="table-skeleton__input" />
            </template>
            <template #cell(1)>
                <ui-skeleton class="table-skeleton__input" />
            </template>
            <template #cell(2)>
                <ui-skeleton class="table-skeleton__input" />
            </template>
            <template #cell(3)>
                <ui-skeleton class="table-skeleton__input" />
            </template>
            <template #cell(4)>
                <ui-skeleton class="table-skeleton__input" />
            </template>
            <template #cell(5)>
                <ui-skeleton class="table-skeleton__input" />
            </template>
            <template #cell(6)>
                <ui-skeleton class="table-skeleton__input" />
            </template>
        </ui-table>
    </div>
</template>

<script>
import UiSkeleton from '@/components/ui/skeleton/Skeleton.vue';
import UiTable from '@/components/ui/Table.vue';

export default {
    name: 'TableSkeleton',
    components: { UiSkeleton, UiTable },
    data() {
        return{
            fields: [
                { key: '1', label: '' },
                { key: '2', label: '' },
                { key: '3', label: '' },
                { key: '4', label: '' },
                { key: '5', label: '' },
                { key: '6', label: '' },
            ],
            items: [{}, {}, {}, {}, {}, {}],
        };
    },
};
</script>

<style lang="scss">
.table-skeleton {
    &__input {
        width: 90px;

        @media (max-width: 768px) {
            width: 100%;
        }
    }
}
</style>
